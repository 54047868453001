<template>
  <v-sheet>
    <component :is="currentRole" />
  </v-sheet>
</template>

<script>
import { mapGetters } from "vuex";
import i18n from "@/i18n";
export default {
  name: "ReportCard",
  metaInfo: {
    title: i18n.t("routes.report_card"),
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    ReportCardAdmin: () => import("./ReportCardAdmin"),
    ReportCardStudent: () => import("./ReportCardStudent")
  },
  data() {
    return {
      currentRole: "ReportCardAdmin"
    };
  },
  computed: mapGetters(["g_role_type"]),
  created() {
    // const showTab = [];
    // this.tabCode.map(d => {
    //   showTab.push(this.g_privilages_user.includes(d));
    // });
    if (this.g_role_type === "STUDENT") {
      this.currentRole = "ReportCardStudent";
    } else {
      this.currentRole = "ReportCardAdmin";
    }
  }
};
</script>
